import { Box, Toolbar } from '@mui/material'
import { matchPath, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../Redux/actions/menuActions';

import Drawer from './Drawer';
import Header from './Header';
import { useEffect, useState } from 'react';
import { allPaths } from '../../utils/constants';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { removeAdmin } from '../../Redux/actions/adminAuthActions';

const configureAxiosInterceptors = (dispatch, navigate, customer) => {
  axios.interceptors.request.use(
    (request) => {
      if (customer?.accessToken) {
        request.headers.Authorization = `Bearer ${customer.accessToken}`;
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("configureAxiosInterceptors ~ error:", error);
      if (error?.response?.data?.message == "TokenError") {
        enqueueSnackbar('Session Expired!', { variant: 'warning' });
        navigate(allPaths.HOME);
        dispatch(removeAdmin());
      }else if(error?.code == "ERR_BAD_REQUEST"){
        enqueueSnackbar(error?.response?.data?.message, { variant: 'warning' });
        navigate(allPaths.HOME);
        dispatch(removeAdmin());
      }
      return Promise.reject(error);
    }
  );
};

function CustomerLayout() {

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menuReducer);
  const { admin: customer } = useSelector((state) => state.adminAuthReducer || { admin: null });
  const navigate = useNavigate();

  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    configureAxiosInterceptors(dispatch, navigate, customer);
  });

  useEffect(() => {
    if (!customer) {
      navigate(allPaths.CUSTOMER.LOGIN);
    }
  }, [customer]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>

      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />

      <Box
          component="main"
          sx={{
            width: open ? `calc(100vw - 240px)` : '100vw',
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            overflowX: 'hidden',
          }}
        >
          <Toolbar />
          {/* <Breadcrumbs navigation={navigation(admin?.userRole, admin?.permissionGroup)} title /> */}
          <Outlet />
        </Box>
    </Box>
  )
}

export default CustomerLayout