import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme, styled } from '@mui/material';
import React from 'react';
import { SectionDescription, SectionTitle, SectionWrapper } from '../../Components/Common';

const MemberName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '25px'
  },
  lineHeight: 1.4,
  fontWeight: 400,
  color: theme.palette.custom.smoke,
  marginTop: '15px'
}));

const MemberDesignation = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px'
  },
  lineHeight: 1.4,
  fontWeight: 400,
  color: theme.palette.primary.dark
  // textAlign: 'left'
}));

// eslint-disable-next-line no-unused-vars
const About = (props) => {
  const theme = useTheme();

  const advisoryTeam = [
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/c65498e3-9b4c-404a-e535-bee19d5e5700/public',
      name: 'John Hart',
      intro:
        'MIT Professor, co-founder of Desktop Metal (IPO), and co-founder of VulcanForms 3D printing service ($355m raised as of Dec 2021)'
    },
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/67218f94-1587-4a69-1c40-dd923d8f7e00/public',
      name: 'Dale Bertrand',
      intro: 'Founder and CEO of Fire & Spark, a manufacturing focused SEO agency'
    },
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/228f1cd4-8efb-40c0-cf86-e2d3cb404200/public',
      name: 'Cora Leibig',
      intro: 'Founder and CEO of Chromatic 3D Materials ($8.45m raised as of Jan 2022)'
    },
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/16f2dfa2-33c4-47b8-b60d-18a56178a600/public',
      name: 'John Howard',
      intro: 'Founder of MIT Horizons, specializing in marketing and press for emerging technology'
    },
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/1683992a-764b-4b50-1f3d-86c4328b1f00/public',
      name: 'Dustin Kloempken',
      intro: 'Engineer at HP 3D Printing'
    },
    {
      photoUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/50247f9f-f1f4-498b-680e-db4dd4b92c00/public',
      name: 'Martin Culpepper',
      intro: 'Professor of Mechanical Engineering at MIT, renowned for his expertise in precision engineering and manufacturing'
    }
  ];

  return (
    <>
      {/* Welcome to Spokbee */}
      <SectionWrapper>
        <Container>
          <SectionTitle>
            <Typography variant="span">Welcome to </Typography>
            <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
              Spokbee Inc.
            </Typography>
          </SectionTitle>
          <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
            The revolutionary software platform that is redefining the way customizable products are created, sold, and manufactured online.
            We seamlessly connect sellers, buyers, and manufacturers in one digital space, simplifying collaboration and streamlining the
            entire process of launching, customizing, and delivering high-quality products.
          </SectionDescription>
        </Container>
      </SectionWrapper>
      {/* Our Vision */}
      <SectionWrapper sx={{ background: theme.palette.primary.dark05 }}>
        <Container>
          <Grid container rowSpacing={4}>
            <Grid item xs={12} sm={8}>
              <SectionTitle>
                <Typography variant="span">Our </Typography>
                <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
                  Vision
                </Typography>
              </SectionTitle>
              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                At Spokbee, we believe in the power of cutting-edge technology to transform the world of mass customization. Our platform
                leverages the latest developments in manufacturing and cloud-based computer-aided design (CAD) to create a one-stop
                marketplace for bespoke products that are both on-demand and scalable.
              </SectionDescription>
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={3}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/d4bc34f1-e4e2-4f10-2833-314f27e40500/public"
                sx={{
                  maxWidth: '300px'
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {/* Our Mission */}
      <SectionWrapper>
        <Container>
          <Grid
            container
            rowSpacing={4}
            sx={{
              flexDirection: {
                xs: 'column-reverse',
                sm: 'row'
              }
            }}
          >
            <Grid item xs={12} sm={3}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/1ef7512c-f1d7-44b5-7e4e-c014c8dd7a00/public"
                sx={{
                  maxWidth: '300px'
                }}
              />
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={8}>
              <SectionTitle>
                <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
                  Our{' '}
                </Typography>
                <Typography variant="span">Mission</Typography>
              </SectionTitle>
              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                We strive to empower sellers, buyers, and manufacturers with the tools and resources needed to succeed in manufacturing. By
                solving challenges related to product development, supply chain management, and pricing, we not only make it easier for our
                users to bring their ideas to life, but also contribute to a more sustainable and innovative future.
              </SectionDescription>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {/* Our Solution */}
      <SectionWrapper sx={{ background: theme.palette.primary.dark05 }}>
        <Container>
          <Grid container rowSpacing={4}>
            <Grid item xs={12} sm={8}>
              <SectionTitle>
                <Typography variant="span">Our </Typography>
                <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
                  {' '}
                  Solution
                </Typography>
              </SectionTitle>
              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                Spokbee&apos;s proprietary software simplifies the process of launching customizable products, enabling sellers to automate
                custom pricing and accelerate product development. Buyers benefit from faster turnaround times and greater control over
                product customization, while manufacturers gain access to a wealth of new business opportunities, robust CAD designs, and
                advanced pricing algorithms.
              </SectionDescription>
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={3}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/6fc254aa-d54b-448e-236d-6ccb4ed85500/public"
                sx={{
                  maxWidth: '300px'
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {/* Our Business Model */}
      <SectionWrapper>
        <Container>
          <Grid
            container
            rowSpacing={4}
            sx={{
              flexDirection: {
                xs: 'column-reverse',
                sm: 'row'
              }
            }}
          >
            <Grid item xs={12} sm={3}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/a90c01d6-4aae-4822-c12b-ca0789311a00/public"
                sx={{
                  maxWidth: '300px'
                }}
              />
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={12} sm={8}>
              <SectionTitle>
                <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
                  Our{' '}
                </Typography>
                <Typography variant="span">Business Model</Typography>
              </SectionTitle>
              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                We generate revenue by earning a commission on each part sold through Spokbee.com, and also offer premium storefront add-ons
                tailored to the unique needs of businesses and enterprises. Our platform is currently in its beta version, with plans to
                integrate with the world&apos;s fastest-growing CAD platform, Onshape, in the near future. This partnership will open the
                door to exponential growth, as we tap into a user base of over 2 million CAD designers. Join us as we revolutionize the
                world of customizable products and make the future of manufacturing a reality today.
              </SectionDescription>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {/* Our Team and Advisors */}
      <SectionWrapper sx={{ background: theme.palette.primary.dark05 }}>
        <Container>
          <SectionTitle sx={{ textAlign: 'center' }}>
            <Typography variant="span">Our Team and </Typography>
            <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
              Advisors
            </Typography>
          </SectionTitle>
          <SectionDescription sx={{ maxWidth: { xs: '100%', md: '850px', margin: '0 auto' } }}>
            Mac Cameron, Chief Executive Officer and Karina Cameron, Chief Technical Officer are the dynamic co-founders of Spokbee Inc.
            Together, they bring a wealth of industry experience and technical expertise to the table.
          </SectionDescription>
          <Grid container spacing={8} sx={{ marginTop: '0px' }}>
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/c7860dd3-d2fc-4510-c459-5f8c94eb2300/public"
                sx={{
                  maxWidth: '224px'
                }}
              />
              <MemberName>Mac Cameron</MemberName>
              <MemberDesignation>Chief Executive Office</MemberDesignation>

              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                Mac has spent a decade working in the 3D printing and manufacturing industry, including a role as an application engineer at
                Stratasys. He also served as the lead instructor for the Design for 3D Printing course at the Massachusetts Institute of
                Technology (MIT).
              </SectionDescription>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/f9b15a64-1e63-4421-ab05-0231c087b800/public"
                sx={{
                  maxWidth: '224px'
                }}
              />
              <MemberName>Karina Cameron</MemberName>
              <MemberDesignation>Chief Executive Office</MemberDesignation>

              <SectionDescription sx={{ maxWidth: '100% !important', textAlign: 'left' }}>
                Karina, is the visionary architect and driving force behind the technical aspects of the platform. She built the original
                Spokbee software, laying the foundation for its continued growth and cutting-edge innovation. Karina leads Spokbee&apos;s
                talented team of technical professionals ensuring scalability, reliability, and security.
              </SectionDescription>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {/* Remote-First Work Culture */}
      <SectionWrapper sx={{ background: theme.palette.secondary.main }}>
        <Container>
          <SectionTitle sx={{ color: theme.palette.custom.white }}>Remote-First Work Culture</SectionTitle>
          <SectionDescription sx={{ color: theme.palette.custom.white, textAlign: 'left' }}>
            At Spokbee, we prioritize flexibility and efficiency. Our 100% remote work culture allows us to select top talent from around
            the globe while minimizing overhead costs. This approach empowers our team members to excel in their preferred work environments
            and contribute to the ongoing success of our platform.
          </SectionDescription>
        </Container>
      </SectionWrapper>
      {/* Advisory Board */}
      <SectionWrapper sx={{ background: theme.palette.primary.dark05 }}>
        <Container>
          <SectionTitle sx={{ textAlign: 'center' }}>
            <Typography variant="span">Advisory </Typography>
            <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
              Board
            </Typography>
          </SectionTitle>
          <SectionDescription sx={{ maxWidth: { xs: '100%', md: '850px', margin: '0 auto' } }}>
            Spokbee Inc. is proud to have an experienced group of advisors, each bringing unique skills within the realms of 3D printing,
            marketing, and manufacturing. Together, our team and advisors drive Spokbee&apos;s mission to revolutionize the world of
            customizable products, shaping a brighter future for the manufacturing industry.
          </SectionDescription>
          <Grid container spacing={8} sx={{ marginTop: '0px' }} justifyContent="center">
            {advisoryTeam?.map((member, i) => {
              return (
                <Grid item xs={12} sm={4} md={2} key={`advisory-team-${i}`}>
                  <Box
                    component="img"
                    src={member?.photoUrl}
                    sx={{
                      maxWidth: '180px'
                    }}
                  />
                  <MemberName sx={{ color: theme.palette.primary.dark }}>{member?.name}</MemberName>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: 1.4
                    }}
                  >
                    {member?.intro}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default About;
