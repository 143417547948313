import { Box, CircularProgress, Grid, Stack, Input, InputAdornment, Typography, FormLabel, Slider, FormControlLabel, FormGroup, Checkbox, Card } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { SectionTitle } from '../Common';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import CanvasModel from '../Editor/CanvasModel';
import Select from 'react-select';
import { enqueueSnackbar } from 'notistack';
import WarningIcon from '@mui/icons-material/Warning';
import DownloadCADFileModal from "../../Components/Editor/DownloadCADFileModal"
import { Divider, useTheme } from '@mui/material';
import { debounce } from "lodash";

const materialOptions = [
    {
        value: "GG",
        label: "Gray Cast Iron"
    },
    {
        value: "ALU",
        label: "Aluminum"
    }
];
const coatingOptions = [
    {
        value: '',
        label: 'None',
        material: ["GG", "ALU"]
    },
    {
        value: 'CH',
        label: 'Chemically Nickel Plated',
        material: ["GG", "ALU"]
    },
    {
        value: 'BW',
        label: 'Coating Type W',
        material: ["GG", "ALU"  ]
    },
    {
        value: 'NE',
        label: 'Natural Anodized',
        material: ["ALU"]
    },
    {
        value: 'SE',
        label: 'Black Anodized',
        material: ["ALU"]
    }
];
const operationOptions = [
    {
        value: 'MI',
        label: 'Micrometer Screw',
        types: ["RM", "TVM", "SM", "NOM"]
    },
    {
        value: 'HR',
        label: 'HandWheel',
        types: ["RM", "TVM", "SM", "NOM"]
    },
    {
        value: 'KK',
        label: 'Crank handle',
        types: ["RM", "TVM", "SM", "NOM"]
    },
    {
        value: 'FW',
        label: 'Free Shaft end',
        types: ["RM", "TVM", "SM", "NOM"]
    }
];
const clampStyleOptions = [
    {
        value: '',
        label: 'None',
        types: ["NOE", "NOG", "NOM", "NOO", "NOZ", "RE", "RG", "RM", "RO", "RZ", "SE", "SG", "SM", "SO", "SZ", "TVE", "TVG", "TVM", "TVO", "TVZ"]
    },
    {
        value: 'F',
        label: 'Clamping Type F',
        types: ["", "NOE", "NOG", "NOM", "NOO", "RE", "RG", "RM", "RO", "TVE", "TVG", "TVM", "TVO"]
    },
    {
        value: 'ZL',
        label: 'Clamping Type ZL',
        types: ["SE", "SG", "SM", "SO"]
    },
    {
        value: 'SKL',
        label: 'Clamping Type SKL',
        types: ["RM", "TVM", "SM", "NOM"]
    }
];
const clampingLeftOptions = [
    {
        value: "",
        label: 'None',
    },{
        value: "KL",
        label: 'Clamping on left side',
    }
];
const drillingOptions = [
    {
        value: 'SB',
        label: 'With Drilling Pattern (standard)'
    }
];
const nonFilterableOpts = ['material', "coating", "operation", "clamping", "clapmingLeft", "drilling"]

const EroStoreFront = ({ modelId, modelData, modelRefreshing, pricingRefreshing, setModelRefreshing, canvas, rotationCamera, cameraRorationValue, boundingBoxDimentions, ProductDimentionRef, setProductDimensions, StorLocNewSchema, jsonData, setConfigurationInputs, currentConfiguration, isPreview }) => {
    const theme = useTheme();
    const [catalogData, setCatalogData] = useState({
        imageValues: [],
        headers: [],
        numericColumns: [],
        filterColumns: {},
        columns: [],
        secondHeader: {},
        headerSecondHeaderMappings: {}
    });
    const [catalogItems, setCatalogItems] = useState([]);
    const [allCatalogItems, setAllCatalogItems] = useState([])
    const [pageState, setPageState] = useState({ page: 0, pageSize: 10, search: '' });
    const [searchValue, setSearchValue] = useState("")
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [catalogFilter, setCatalogFilter] = useState({});
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        GetCatlogData()
    }, []);

    useEffect(() => {
        // Apply filters to the catalog items
        const filteredRows = allCatalogItems.filter((row) => {
            return Object.keys(catalogFilter).filter(col => nonFilterableOpts.includes(col) == false).every((col) => {
                if(catalogFilter[col] == "BasePN"){
                    return row["BasePN"].includes(catalogFilter["BasePN"]);
                }else if (typeof catalogFilter[col] === "string") {
                    // Single-selection dropdown filter
                    return row[col]?.toString().toLowerCase() === catalogFilter[col].toLowerCase();
                } else {
                    // Slider filter: check if value is within range
                    const columnValue = Number(row[col]);
                    return columnValue >= catalogFilter[col][0] && columnValue <= catalogFilter[col][1];
                }
            });
        });

        // Update state in one call to minimize re-renders
        showDisplayPartNumber();
        setCatalogItems(filteredRows);
        if(searchValue != "" && catalogFilter?.BasePN && catalogFilter?.BasePN != ""){
            setSelectedRow(filteredRows[0])
            refreshmodel()
        }
    }, [catalogFilter]);



    const GetCatlogData = async () => {
        const displayColumnsOnly = ["BasePN", "T1", "T2", "A", "C", "B", "D", "E", "E1", "L", "FDD", "FQD", "KG"]
        setIsTableLoading(true)
        try {
            const sellerID = "64feab5f0bf24e6122ebe4ae"
            const res = await axios.get(`/api/get/seller/catalog-data/${sellerID}`);
            const { data, secondHeader, headers, numericColumns, filterColumns } = res.data;

            const rows = data.map((row, rowId) => {
                let rowData = {};
                secondHeader.forEach((columnName, i) => {
                    rowData.id = rowId + 1;
                    rowData[columnName] = ([4, 5].includes(i)) ? row[i].padStart(3, "0") : row[i];
                });
                return rowData;
            });
            let headerSecondHeaderMappings = {} 
            secondHeader.forEach((col, index) => {
                headerSecondHeaderMappings[col] = headers[index]
            });

            const columns = secondHeader.filter((col) => displayColumnsOnly.includes(col)).map((columnName) => {
                return {
                    field: columnName,
                    headerName: headerSecondHeaderMappings[columnName],
                    filterable: Object.keys(filterColumns).includes(columnName),
                    type: numericColumns.includes(columnName),
                    hideable: columnName == "BasePN" ? true : false
                };
            });

            setCatalogItems(rows);
            setAllCatalogItems(rows);
            setCatalogData({
                // imageValues: imageValues,
                headers: headers,
                numericColumns: numericColumns,
                filterColumns: filterColumns,
                columns: columns,
                secondHeader: secondHeader,
                headerSecondHeaderMappings
            });
        } catch (error) {
            console.error("Error fetching catalog data:", error);
        } finally {
            setIsTableLoading(false)
        }
    };
    
    const handlePaginationModelChange = (params) => {
        setPageState((prev) => ({
            ...prev,
            page: params.page,
            pageSize: params.pageSize
        }));
    };

    const handleFilterChange = (colHeader, type, opt, sliderMinVal = 0, sliderMaxVal = 100, isFilter = false) => {    
        setCatalogFilter((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            
            if (type === "dropdown") {
                // Initialize filter object if not present
                if (!updatedFilters[colHeader]) updatedFilters[colHeader] = null;
                
                // Toggle selection: if option is already selected, remove it
                if (opt == null) {
                    delete updatedFilters[colHeader]; // Remove selected option
                } else {
                    updatedFilters[colHeader] = opt?.value; // Add selection
                }
                if(["T1", "T2"].includes(colHeader)){
                    delete updatedFilters["BasePN"]
                    setSearchValue("")
                }
            }else if (type === "slider") {
                if(opt[0] == sliderMinVal && opt[1] == sliderMaxVal){
                    delete updatedFilters[colHeader]
                }else{
                    updatedFilters[colHeader] = opt; // Store slider value
                }
            }
            
            return updatedFilters;
        });
    };

    const debouncedSearch = useCallback(
        debounce((searchValue, allCatalogItems) => {
            if(searchValue.includes("-") && searchValue.substr(searchValue.length -1) != "-"){
                let tempCatalogFilter = {...catalogFilter}
                const parts = searchValue.split("-").map(opt => opt.trim())

                
                const basepartNumber = parts[0]
                const isvalidBasePartNo = allCatalogItems.some(row => {
                    return row["BasePN"] == basepartNumber
                })

                const foundBlankOptions = parts.some(part => part == "")
                
                if(foundBlankOptions){
                    enqueueSnackbar("Invalid Part Number", {variant: "warning"})
                }else if(!isvalidBasePartNo){
                    enqueueSnackbar("Invalid Part Number: Base part number does not match", {variant: "danger"})
                }else{
                    const materialOpts = materialOptions.filter((opt) => parts.includes(opt.value));
                    const coatingOpts = coatingOptions.filter((opt) => parts.includes(opt.value));
                    const operationOpts = operationOptions.filter((opt) => parts.includes(opt.value));
                    const clampStyleOpts = clampStyleOptions.filter((opt) => parts.includes(opt.value));
                    const clampingLeftOpts = clampingLeftOptions.filter((opt) => parts.includes(opt.value));
                    
                    if(materialOpts.length > 1){
                        enqueueSnackbar("Invalid Part Number: Found multiple material", {variant: "warning"})
                    }else if(coatingOpts.length > 1){
                        enqueueSnackbar("Invalid Part Number: Found multiple coating", {variant: "warning"})
                    }else if(operationOpts.length > 1){
                        enqueueSnackbar("Invalid Part Number: Found multiple operation", {variant: "warning"})
                    }else if(clampStyleOpts.length > 1){
                        enqueueSnackbar("Invalid Part Number: Found multiple clamping style", {variant: "warning"})
                    }else if(clampingLeftOpts.length > 1){
                        enqueueSnackbar("Invalid Part Number", {variant: "warning"})
                    }else{
                        if(materialOpts.length == 0){
                            delete tempCatalogFilter.material
                        }else{
                            tempCatalogFilter.material = materialOpts[0]?.value;
                        }
                        if(operationOptions.length == 0){
                            delete tempCatalogFilter.operation
                        }else{
                            tempCatalogFilter.operation = operationOpts[0]?.value;
                        }
                        
                        tempCatalogFilter.coating = coatingOpts[0]?.value || "";
                        tempCatalogFilter.clamping = clampStyleOpts[0]?.value || "";
        
                        if(tempCatalogFilter.clamping == "" && clampingLeftOpts.length > 0){
                            enqueueSnackbar("Clamp Left will not applied bcoz clamping style is not selected", {variant: "warning"})
                        }else if(tempCatalogFilter.clamping == ""){
                            tempCatalogFilter.clapmingLeft = ""
                        }else{
                            tempCatalogFilter.clapmingLeft = clampingLeftOpts[0]?.value || "";
                        }
        
                        setCatalogFilter(tempCatalogFilter);
                    }                    
                    
                }
    
                setCatalogFilter((prev) => {
                    const updatedFilters = { ...prev };
                    if(basepartNumber == ""){
                        delete updatedFilters["BasePN"]
                        delete updatedFilters["T1"]
                        delete updatedFilters["T2"]
                        return updatedFilters
                    }else{
        
                        return {
                            ...updatedFilters,
                            "BasePN": basepartNumber
                        }
                    }
                })
            }
        }, 500), // 500ms delay
        []
    );
    
    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        setSearchValue(searchValue);
        debouncedSearch(searchValue, allCatalogItems);
    };


        
    const handleRowClick = (params) => {
        const row = params.row;
        setSelectedRow(row);
    }

    useEffect(() => {
        refreshmodel()
    }, [selectedRow])

    const refreshmodel = () => {
        const row = selectedRow

        if(Object.keys(row).length > 0){
            if(row["T2"] == "M" && typeof catalogFilter.operation == 'undefined'){
                enqueueSnackbar("Please select operaion", { variant: "warning"});
            }else if(row["T2"] != "Z" && typeof catalogFilter.clamping == undefined){
                enqueueSnackbar("Please select clamping", { variant: "warning"});
            }else if(typeof catalogFilter.material == undefined || catalogFilter?.material == ""){
                enqueueSnackbar("Please select material", { variant: "warning"});
            }else if(typeof catalogFilter.coating == undefined){
                enqueueSnackbar("Please select coating", { variant: "warning"});
            }
            /*else if(typeof catalogFilter.drilling == undefined || catalogFilter?.drilling == ""){
                enqueueSnackbar("Please select drilling pattern", { variant: "warning"});
            }*/
            else{
                let  outputJSON = {}
                const outputColumns = ["T1", "T2", "A", "C", "B", "D", "E", "E1", "L", "G", "H", "J", "K", "N", "O", "R", "S", "T", "KF", "SP", "U", "X", "Y", "Z", "T1_O", "T1_XY", "C_O", "C_I", "C_B", "O_A", "O_EP", "Hole_EP", "AT_XY", "IT_XY", "ECC"];
                const numericColumns = ["A", "C", "B", "D", "E", "E1", "L", "G", "H", "K", "N", "O", "R", "S", "T", "KF", "U", "Y", "Z", "C_O", "C_I", "C_B", "O_A"];
                const arrayJsonColumns = ["DIAL", "T2Z", "MI", "KK", "HR", "FW", "F", "ZL", "SKL", "Hole_EP", "T1_O", "T1_XY", "O_EP", "AT_XY", "IT_XY", "ECC"]
                outputColumns.forEach((col) => {
                    outputJSON[col] = row[col]
                })
                outputJSON["Mat"] = catalogFilter.material
                outputJSON["Coat"]= catalogFilter.coating
                outputJSON["TI"]= true
                outputJSON["TA"]= true
                outputJSON["DP"] = catalogFilter.drilling
    
    
                if(outputJSON["T2"] == 'M'){
                    outputJSON["Op"] = catalogFilter.operation
                    if(outputJSON["Op"] != "FW"){
                        outputJSON["DIAL"] = row["DIAL"]
                    }
                    outputJSON[catalogFilter.operation] = row[catalogFilter.operation]
                }else if (outputJSON["T2"] == "Z") { 
                    outputJSON["T2Z"] = row["T2Z"]; 
                }
                if(catalogFilter?.clamping){
                    outputJSON[catalogFilter.clamping] = row[catalogFilter?.clamping]
                }
                if(catalogFilter?.clamping && catalogFilter?.clamping != ""){
                    outputJSON["Cl"] = catalogFilter?.clamping;
                    outputJSON["KL"] = (catalogFilter?.clapmingLeft == "KL") ? true : false
                }
                
                numericColumns.forEach(col => {
                    if(outputJSON[col] && outputJSON[col] != ""){
                        outputJSON[col] = outputJSON[col]*1
                    }
                });
    
                arrayJsonColumns.forEach(col => {
                    if(outputJSON[col] && outputJSON[col] != ""){
                        outputJSON[col] = JSON.parse(outputJSON[col])
                    }
                });
                showDisplayPartNumber()
                setConfigurationInputs({JSON: JSON.stringify(outputJSON)});
                // console.log(" handleRowClick ~ outputJSON:", outputJSON);
            }
        }
    }

    const showDisplayPartNumber = () => {
        // console.log(" EroStoreFront.jsx:406 ~ showDisplayPartNumber ~ catalogFilter:", catalogFilter);
        if(selectedRow?.BasePN && selectedRow?.BasePN != ""){
            let displaypartNumber = `${selectedRow?.BasePN}`;
            if(catalogFilter?.material){
                displaypartNumber += `-${catalogFilter?.material}`
            }
            if(catalogFilter?.coating){
                displaypartNumber += `-${catalogFilter?.coating}`
            }
            if(catalogFilter?.operation){
                displaypartNumber += `-${catalogFilter?.operation}`
            }
            if(catalogFilter?.clamping){
                displaypartNumber += `-${catalogFilter?.clamping}`
                if(catalogFilter?.clapmingLeft){
                    displaypartNumber += `-${catalogFilter?.clapmingLeft}`
                }
            }

            setSearchValue(displaypartNumber);
        }
    }

    return (
        <Box sx={{ textAlign: 'left', padding: { xs: '20px 10px', sm: '30px' } }}>
            <Grid container spacing={4}>

                <Grid container item xs={12} justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2}>
                            <SectionTitle>{modelData?.modelName}</SectionTitle>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Input
                        placeholder="Enter model ID"
                        value={searchValue}
                        onChange={handleSearchChange}
                        disabled={isTableLoading == true}
                        sx={{
                            width: '100%',
                            padding: { xs: '10px', sm: '16px' },
                            fontSize: { xs: '14px', sm: '16px' },
                            background: "#323035",
                            color: "#fff",
                            borderRadius: '4px',
                        }}
                        startAdornment={
                            <InputAdornment position="start" >
                                <Typography sx={{ color: '#fff' }}>Display Part:</Typography>
                            </InputAdornment>
                        }
                    />
                </Grid>

                <Grid item md={8}>
                    <CanvasModel 
                        modelRefreshing={modelRefreshing}
                        pricingRefreshing={pricingRefreshing}
                        canvas={canvas}
                        rotationCamera={rotationCamera}
                        cameraRorationValue={cameraRorationValue}
                        boundingBoxDimentions={boundingBoxDimentions}
                        ProductDimentionRef={ProductDimentionRef}
                        setProductDimensions={setProductDimensions}
                        StorLocNewSchema={StorLocNewSchema}
                        jsonData={jsonData} 
                    />
                    <Box sx={{marginTop: "30px"}}>
                        <DataGrid
                            rows={catalogItems}
                            columns={catalogData?.columns}
                            rowCount={catalogItems.length}
                            paginationMode="client"
                            pagination
                            loading={isTableLoading}
                            paginationModel={pageState}
                            pageSizeOptions={[10, 20, 30]}
                            onPageChange={(newPage) => {
                                setPageState({ ...pageState, page: newPage });
                            }}
                            // filterModel={{
                            //     items: [{ field: 'BasePN', operator: 'contains', value: catalogFilter['BasePN'] }],
                            // }}
                            onPaginationModelChange={handlePaginationModelChange}
                            disableSelectionOnClick
                            sx={{ border: '1px solid #ddd', borderRadius: '4px' }}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableColumnSorting
                            autoHeight
                            initialState={{
                                columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    "BasePN": false,
                                },
                                },
                            }}
                            onRowClick={handleRowClick}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2} sx={{ marginBottom: '15px' }}>
                        {modelData.downloadCAD && (
                            <DownloadCADFileModal
                                // downloadCADFile={DownloadCADFile}
                                isPreview={isPreview}f
                                modelRefreshing={modelRefreshing}
                                setModelRefreshing={setModelRefreshing}
                                productPrice={10}
                                modelId={modelId}
                                currentConfiguration={currentConfiguration}
                            />
                        )}
                    </Box>
                    <Card
                        sx={{
                            border: `1px solid ${theme.palette.primary.dark}`,
                            borderRadius: '10px',
                            marginBottom: '15px'
                        }}
                        className='configurator-wrapper'
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 2 }}
                            style={{
                            maxHeight: '60vh',
                            overflowY: 'scroll',
                            scrollbarWidth: 'none',
                            '&::WebkitScrollbar': {
                                display: 'none'
                            }
                            }}
                        >
                            <Grid item xs={12} position="relative">
                                {modelRefreshing ? (
                                    <WarningIcon
                                    fontSize="medium"
                                    style={{
                                        color: 'orange',
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        margin: '2px'
                                    }}
                                    ></WarningIcon>
                                ) : (
                                    <></>
                                )}
                                <>
                                    <FormLabel component="legend">Material</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        options={materialOptions || []}
                                        value={materialOptions?.find((c) => {
                                        return c?.value == catalogFilter.material
                                        }) || null}
                                        onChange={(selectedOption) => {
                                            handleFilterChange("material", "dropdown", selectedOption, null, null, true)
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </>
                                <>
                                    <FormLabel component="legend">Coating</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        // isxDisabled={catalogFilter?.material == null}
                                        options={coatingOptions || []}
                                        value={coatingOptions?.find((c) => {
                                        return c?.value == catalogFilter.coating
                                        }) || null}
                                        onChange={(selectedOption) => {
                                            handleFilterChange('coating', "dropdown", selectedOption, null, null, true)
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                        isOptionDisabled={(option) =>{
                                            return !option.material.includes(catalogFilter?.material)
                                        }}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </>
                                <>
                                    <FormLabel component="legend">Operation</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        isDisabled={!(catalogFilter?.T2 == "M" || selectedRow?.T2 == "M" || catalogFilter?.BasePN?.includes("M"))}
                                        options={operationOptions || []}
                                        value={operationOptions?.find((c) => {
                                            return c?.value == catalogFilter.operation
                                        }) || null}
                                        menuPortalTarget={document.querySelector('body')}
                                        // isOptionDisabled={(option) =>{
                                        //     const types = `${catalogFilter?.T1}${catalogFilter?.T2}`
                                        //     return !option.types.includes(types)
                                        // }}
                                        onChange={(selectedOption) => {
                                            handleFilterChange('operation', "dropdown", selectedOption, null, null, true)
                                        }}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </>
                                <>
                                    <FormLabel component="legend">Clamping</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        // isDisabled={!(catalogFilter?.T1 == null || catalogFilter.T2 == null || Object.key(selectedRow) > 0)}
                                        options={clampStyleOptions || []}
                                        value={clampStyleOptions?.find((c) => {
                                            return c?.value == catalogFilter.clamping
                                        }) || null}
                                        menuPortalTarget={document.querySelector('body')}
                                        isOptionDisabled={(option) =>{
                                            const types = Object.keys(selectedRow) > 0 ? `${selectedRow?.T1}${selectedRow?.T2}` : `${catalogFilter?.T1}${catalogFilter?.T2}`
                                            console.log(" EroStoreFront.jsx:665 ~ EroStoreFront ~ types:", types);
                                            if(catalogFilter?.BasePN){
                                                const parts = catalogFilter?.BasePN.split(" ")
                                                return !option.types.includes(parts[0])
                                            }
                                            return !option.types.includes(types)
                                        }}
                                        onChange={(selectedOption) => {
                                            handleFilterChange('clamping', "dropdown", selectedOption, null, null, true)
                                        }}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </>
                                <>
                                    <FormLabel component="legend">Clamping left</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        isDisabled={catalogFilter?.clamping == null}
                                        options={clampingLeftOptions || []}
                                        value={clampingLeftOptions?.find((c) => {
                                        return c?.value == catalogFilter.clapmingLeft
                                        }) || null}
                                        onChange={(selectedOption) => {
                                            handleFilterChange('clapmingLeft', "dropdown", selectedOption, null, null, true)
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </>
                                {/* <>
                                    <FormLabel component="legend">Drilling Pattern</FormLabel>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        options={drillingOptions || []}
                                        value={drillingOptions?.find((c) => {
                                        return c?.value == catalogFilter.drilling
                                        }) || null}
                                        onChange={(selectedOption) => {
                                            handleFilterChange('drilling', "dropdown", selectedOption, null, null, true)
                                        }}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{
                                            menu: (provided) => ({
                                                ...provided,
                                                marginBottom: 0,
                                                marginTop: 0
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                paddingTop: 0,
                                                paddingBottom: 0
                                            })
                                        }}
                                    />
                                </> */}                                
                            </Grid>
                        </Grid>
                    </Card>
                    <Card
                        sx={{
                            border: `1px solid ${theme.palette.primary.dark}`,
                            borderRadius: '10px'
                        }}
                        className='configurator-wrapper'
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 2 }}
                            style={{
                            maxHeight: '60vh',
                            overflowY: 'scroll',
                            scrollbarWidth: 'none',
                            '&::WebkitScrollbar': {
                                display: 'none'
                            }
                            }}
                        >
                            <Grid item xs={12} position="relative">
                                {catalogData?.filterColumns && (<>
                                    {Object.keys(catalogData.filterColumns)?.map((column) => {
                                    const columnData = catalogData?.filterColumns[column]
                                    return (
                                        <React.Fragment key={column}>
                                        {columnData?.isNumeric == false && (
                                            <>
                                                <FormLabel component="legend">{catalogData.headerSecondHeaderMappings[columnData['header']]}</FormLabel>
                                                <Select
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    options={columnData?.options || []}
                                                    value={columnData?.options?.find((c) => {
                                                    return c?.value == catalogFilter[columnData['header']]
                                                    }) || null}
                                                    onChange={(selectedOption) => {
                                                        handleFilterChange(columnData['header'], "dropdown", selectedOption)
                                                    }}
                                                    isOptionDisabled={(option) =>{
                                                        if(columnData["header"] == "T2"){
                                                            if(catalogFilter["T1"] == "S" && option.value == "G"){
                                                                return true;
                                                            }
                                                        }
                                                        return false
                                                    }}
                                                    styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        marginBottom: 0,
                                                        marginTop: 0
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        paddingTop: 0,
                                                        paddingBottom: 0
                                                    })
                                                    }}
                                                />
                                            </>
                                        )}
                                        </React.Fragment>
                                    )
                                    })}
                                    {Object.keys(catalogData.filterColumns)?.map((column) => {
                                    const columnData = catalogData?.filterColumns[column]
                                    return (
                                        <React.Fragment key={column}>
                                        {columnData?.isNumeric == true && (
                                            <>
                                                <FormLabel component="legend">{catalogData.headerSecondHeaderMappings[columnData['header']]}</FormLabel>
                                                <Grid container={true}>
                                                    <Grid item xs alignItems="center" mt={0} ml={2}>
                                                        <Slider
                                                            size="small"
                                                            value={catalogFilter[columnData['header']] || [columnData?.min_val, columnData?.max_val]}
                                                            onChange={(_, value) => {
                                                                handleFilterChange(columnData["header"], "slider", value, columnData?.min_val, columnData?.max_val);
                                                            }}
                                                            max={columnData?.max_val}
                                                            min={columnData?.min_val}
                                                            step={columnData?.step}
                                                            marks={[
                                                            {
                                                                value: columnData?.min_val || 0,
                                                                label: columnData?.min_val || 0,
                                                            },
                                                            ...(catalogFilter[columnData["header"]]?.[0] !== columnData?.min_val
                                                            ? [
                                                                {
                                                                    value: catalogFilter[columnData["header"]]?.[0], // Selected min value
                                                                    label: `${catalogFilter[columnData["header"]]?.[0]}`,
                                                                },
                                                                ]
                                                            : []),
                                                            ...(catalogFilter[columnData["header"]]?.[1] !== columnData?.max_val
                                                            ? [
                                                                {
                                                                    value: catalogFilter[columnData["header"]]?.[1], // Selected max value
                                                                    label: `${catalogFilter[columnData["header"]]?.[1]}`,
                                                                },
                                                                ]
                                                            : []),
                                                            {
                                                                value: columnData?.max_val || 100,
                                                                label: columnData?.max_val || 100
                                                            },
                                                            ]}
                                                            sx={{
                                                            '& .MuiSlider-markLabel[data-index="0"]': {
                                                                transform: 'translateX(0%)'
                                                            },
                                                            '& .MuiSlider-markLabel[data-index="1"]': {
                                                                transform: 'translateX(-100%)'
                                                            }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                        </React.Fragment>
                                    )
                                    })}
                                </>)}                              
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={12} className="product-description">
                    <Typography gutterBottom variant="h4" >
                        About This Item
                    </Typography>
                    <Typography gutterBottom variant="h6" >
                        Instructions
                    </Typography>
                    <Typography paragraph={true}> 
                        1. Use the filter options to narrow down a base configuration of a linear slide
                        <br />                        
                        2. Select a material, coating, and operation/clamping (if applicable)
                        <br />                        
                        3. See your configuration in 3D!
                        <br />                        
                        4. Download CAD or contact us for a quote
                    </Typography>

                    <Typography paragraph={true}> 
                        Configure a linear slide from ERO-Führungen's standard offerings of needle bearing, cross roller, coated guide rail, or dovetail slides. If you can't find what you need on this page, contact us for a customized solution that fits your needs.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default EroStoreFront;
