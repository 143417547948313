// assets
import { DashboardOutlined, UnorderedListOutlined, FileAddOutlined , BulbOutlined } from '@ant-design/icons';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import { allPaths } from '../../utils/constants';

import { MdDashboard, MdAttachMoney, MdShoppingCart  } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";
import { AiFillProduct } from "react-icons/ai";

// icons
const icons = {
  DashboardOutlined,
  UnorderedListOutlined,
  FileAddOutlined,
  MenuIcon,
  BulbOutlined,
  GroupIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

// const onshapeDashboard = {
//   id: 'main-menu',
//   title: '',
//   type: 'group',
//   children: [
//     {
//       id: 'dashboard',
//       title: 'Dashboard',
//       type: 'item',
//       url: allPaths.SELLER.DASHBOARD,
//       icon: icons.DashboardOutlined,
//       breadcrumbs: false,
//       permission: ['onshape', 'privateStorefront', 'shippoShipping']
//     },
//     {
//       id: 'products',
//       title: 'My Products',
//       type: 'item',
//       url: allPaths.SELLER.PRODUCTS,
//       icon: icons.UnorderedListOutlined,
//       breadcrumbs: true
//     },
//     {
//       id: 'tutorial',
//       title: 'Tutorial & Resources',
//       type: 'item',
//       url: allPaths.SELLER.TUTORIAL_AND_RESOURCES,
//       icon: icons.BulbOutlined,
//       breadcrumbs: false
//     }
//   ]
// };

// const shippoShippingDashboard = {
//   id: 'main-menu',
//   title: '',
//   type: 'group',
//   children: [
//     {
//       id: 'dashboard',
//       title: 'Dashboard',
//       type: 'item',
//       url: allPaths.SELLER.DASHBOARD,
//       icon: icons.DashboardOutlined,
//       breadcrumbs: false
//     }
//   ]
// };

// export { onshapeDashboard, shippoShippingDashboard };
const sellerMenus = {
  id: 'main-menu',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: allPaths.SELLER.DASHBOARD,
      icon: MdDashboard,
      breadcrumbs: false,
      permissions: ['onshape', 'privateStorefront', 'shippoShipping']
    },
    {
      id: 'products',
      title: 'My Products',
      type: 'item',
      url: allPaths.SELLER.PRODUCTS,
      icon: AiFillProduct,
      breadcrumbs: true,
      permissions: ['onshape']
    },
    {
      id: 'tutorial',
      title: 'Tutorial & Resources',
      type: 'item',
      url: allPaths.SELLER.TUTORIAL_AND_RESOURCES,
      icon: icons.BulbOutlined,
      breadcrumbs: false,
      permissions: ['onshape']
    },
    {
      id: 'create-label',
      title: 'Create Label',
      type: 'item',
      url: allPaths.SELLER.CREATE_LABEL,
      icon: FileAddOutlined,
      breadcrumbs: true,
      permissions: ['shippoShipping']
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'item',
      url: allPaths.SELLER.ORDERS,
      icon: MdShoppingCart,
      breadcrumbs: true,
      permissions: ['shippoShipping']
    },
    {
      id: 'partners',
      title: 'Our Partner Users',
      type: 'item',
      url: allPaths.SELLER.PARTNERS,
      icon: icons.GroupIcon,
      breadcrumbs: true,
      permissions: ['partners']
    },
    {
      id: 'usres',
      title: 'Our Users',
      type: 'item',
      url: allPaths.SELLER.USERS,
      icon: icons.GroupIcon,
      breadcrumbs: true,
      permissions: ['privateStorefront']
    }
  ]
};

export default sellerMenus;