import * as React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { allPaths } from '../../utils/constants';
import { useState } from 'react';
import { useEffect, useContext } from 'react';
import { SnipcartContext } from '../Context/SnipcartProvider';
import SearchIcon from '@mui/icons-material/Search';
import Person from '@mui/icons-material/Person';
import { useTheme } from '@mui/material';
import SearchBox from './SearchBox';
import Popover from '@mui/material/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../../Redux/actions/authActions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuComponent from './MenuComponent';
import HomeIcon from '@mui/icons-material/Home';
import { Link as MUILink } from '@mui/material';
import { removeAdmin } from '../../Redux/actions/adminAuthActions';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    padding: '0 4px',
    color: '#FFFFFF'
  }
}));

const BookDemoBtn = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: 1.4,
  textTransform: 'none',
  padding: '7px 16px'
}));

const HeaderAppBar = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.adminAuthReducer?.admin);
  const { snipcartReady } = useContext(SnipcartContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const open = Boolean(searchAnchorEl);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSearchAnchorEl(null);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const cart = Snipcart.store.getState().cart.items;
    if (cart.count > 0) {
      cart.items.forEach((item, index) => {
        console.log(item);
        Snipcart.api.cart.items.remove(item?.uniqueId).then(function (item) {
          // console.log(item);
        });
      });
    }
    // Snipcart.api.cart.items.clear().then(function (removedItems) {
    //   console.log(removedItems);
    // });
    dispatch(removeAdmin());
    navigate(allPaths.HOME);
  };

  useEffect(() => {
    if (snipcartReady) {
      const Snip = window.Snipcart;
      const initialState = Snip.store.getState();
      setCartItemsCount(initialState.cart.items.count);
      const unsubscribe = Snip.store.subscribe(() => {
        const newState = Snip.store.getState();
        setCartItemsCount(newState.cart.items.count);
      });

      return () => unsubscribe();
    }
  }, [cartItemsCount, snipcartReady]);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Link style={{ color: 'black' }} to={user?.userRole == 2 ? allPaths.SELLER.MY_PROFILE : allPaths.MY_PROFILE}>
        <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
      </Link>
      {user && user?.userRole == 2 && user?.permissionGroup?.includes('partners') && (
        <Link style={{ color: 'black' }} to={allPaths.SELLER.PARTNERS}>
          <MenuItem onClick={handleMenuClose}>Our Partners Users</MenuItem>
        </Link>
      )}
      {user && user?.userRole == 2 && user?.permissionGroup?.includes('privateStorefront') && (
        <Link style={{ color: 'black' }} to={allPaths.SELLER.USERS}>
          <MenuItem onClick={handleMenuClose}>Our Users</MenuItem>
        </Link>
      )}
      {user && user?.userRole == 3 && (
        <Link style={{ color: 'black' }} to={allPaths.CUSTOMER.ORDERS}>
          <MenuItem onClick={handleMenuClose}>My Orders</MenuItem>
        </Link>
      )}
      <Link style={{ color: 'black' }} to="/logout" onClick={(event) => handleLogout(event)}>
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </Link>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
    >
      {user && (
        <Link style={{ color: 'black' }} to={allPaths.MY_PROFILE}>
          <MenuItem onClick={handleMobileMenuClose}>
            <p style={{ marginBottom: 0 }}>My Profile</p>
          </MenuItem>
        </Link>
      )}
      {!user && (
        <Link style={{ color: 'black' }} to={allPaths.LOGIN}>
          <MenuItem onClick={handleMobileMenuClose}>Sign In/Sign Up</MenuItem>
        </Link>
      )}
      <Link style={{ color: 'black' }} to={allPaths.ABOUT}>
        <MenuItem onClick={handleMobileMenuClose}>About Us</MenuItem>
      </Link>
      <Link style={{ color: 'black' }} to={allPaths.PRICING}>
        <MenuItem onClick={handleMobileMenuClose}>Pricing</MenuItem>
      </Link>
      <Link style={{ color: 'black' }} to={allPaths.LOGIN}>
        <MenuItem onClick={() => window.open('https://calendly.com/maccameron/call-with-mac-spokbee')}>Schedule for Demo</MenuItem>
      </Link>
    </Menu>
  );
  return (
    <Box
      id="herder-wrapper"
      sx={{
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        paddingY: '8px',
        backgroundColor: theme.palette.custom.white
      }}
    >
      <Container id="header" maxWidth="desktop">
        <AppBar
          position="static"
          sx={{
            // top: 0,
            // bottom: 'auto',
            boxShadow: 'none',
            backgroundColor: theme.palette.custom.white
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: props?.isIframe ? 'end' : 'space-between',
              paddingX: {
                xs: '0px'
              }
            }}
          >
            {!props?.isIframe && (
              <Link to="/" style={{ pointerEvents: props?.isIframe || location.pathname === '/pricing' ? 'none' : 'auto' }}>
                <Box component="img" src="/assets/spokbee.png" alt="Spokbee" />
              </Link>
            )}

            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex'
                },
                gap: {
                  xs: '0px',
                  md: '20px'
                },
                alignItems: 'center'
              }}
            >
              {!props?.isIframe && (
                <Box className="search-tool" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <SearchBox />
                </Box>
              )}

              {/* <BookDemoBtn
                className="book-demo-btn"
                variant="outlined"
                onClick={() => window.open('https://calendly.com/maccameron/call-with-mac-spokbee')}
              > */}
              {/* Schedule for Demo */}
              {/* <IconButton style={{ outline: 'none', padding: 0 }} > */}
              {/* <KeyboardArrowRightIcon /> */}
              {/* </IconButton> */}
              {/* </BookDemoBtn> */}

              {/* 9-2-24 changes  remove request beta access button*/}
              {/* Request Beta Access start from here */}
              {/* {
                !props.isIframe && (
                  <BookDemoBtn
                  className="book-demo-btn"
                  variant="outlined"
                  onClick={() => window.open('https://spokbee.typeform.com/early-access')}
                >
                  Request Beta Access
                  <KeyboardArrowRightIcon />
                </BookDemoBtn>
                )
              } */}
              {/* Request Beta Access end from here */}

              {/* 9-2-24 changes  remove conatct and pricing from header */}
              {/* contact and pricing start from here  */}
              {/* {
                !props.isIframe && (
                  <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center' }} spacing={2}>
                    <MenuComponent />
                    <NavLink to={allPaths.PRICING}>Pricing</NavLink>
                  </Stack>
                )
              } */}
              {/* contact and pricing end from here  */}

              {/* 9-2-24 chnages remove signin and my account from header */}
              {/* my contact and signIn start from here */}
              {/* {
                !props?.isIframe && (
                  <Box className="signin-head" sx={{ lineHeight: '45px' }}>
                  {user && (
                    <>
                      <Typography
                        className="header_text"
                        sx={{
                          cursor: 'pointer',
                          color: theme.palette.primary.main
                        }}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        My Account
                      </Typography>
                    </>
                  )}
                  {!user && (
                    <NavLink className="header_text" to={allPaths.LOGIN}>
                      <IconButton style={{ outline: 'none' }}>
                        <Person style={{ color: 'black' }} />
                      </IconButton>
                      Sign In/Sign Up
                    </NavLink>
                  )}
                </Box>
                )
              } */}
              {/* my contact and signIn end from here */}

              {!props.isIframe && (
                <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'center' }} spacing={2}>
                  {/* <MenuComponent /> */}
                  {/* <NavLink to}>Home</NavLink> */}
                  <a href="https://spokbee.com" target="_blank">
                    Home
                  </a>
                </Stack>
              )}
              <Box>
                <IconButton
                  aria-label="cart"
                  style={{
                    outline: 'none'
                  }}
                  className="snipcart-checkout"
                  sx={{ color: theme.palette.secondary.main }}
                >
                  <StyledBadge badgeContent={cartItemsCount} color="primary">
                    <ShoppingCartIcon className="header_text" />
                  </StyledBadge>
                </IconButton>
              </Box>
              {user && [2, 3].includes(user?.userRole) && (
                <Box>
                  <IconButton
                    style={{
                      outline: 'none',
                      width: '30px',
                      height: '30px',
                      border: '2px solid #262626',
                      color: '#262626',
                      borderRadius: '50%',
                      fontSize: '16px'
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    {user?.fname?.charAt(0)?.toUpperCase() || 'N'}
                  </IconButton>
                </Box>
              )}

              {/* <Box className="signin-head" sx={{ lineHeight: '45px' }}>
                  {user && (
                    <>
                      <Typography
                        className="header_text"
                        sx={{
                          cursor: 'pointer',
                          color: theme.palette.primary.main
                        }}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        My Account
                      </Typography>
                    </>
                  )}
                  {!user && (
                    <NavLink className="header_text" to={allPaths.LOGIN}>
                      <IconButton style={{ outline: 'none' }}>
                        <Person style={{ color: 'black' }} />
                      </IconButton>
                      Sign In/Sign Up
                    </NavLink>
                  )}
                </Box> */}
            </Box>

            <Box
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none'
                },
                color: theme.palette.primary.white
              }}
            >
              {!props?.isIframe && (
                <Box>
                  <IconButton
                    size="large"
                    aria-label="Search"
                    aria-controls={'search'}
                    aria-haspopup="true"
                    color="inherit"
                    sx={{
                      paddingRight: {
                        xs: 0
                      }
                    }}
                    onClick={handleClick}
                  >
                    <SearchIcon />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={searchAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    sx={{
                      '.MuiPaper-root': {
                        width: '100%'
                      }
                    }}
                  >
                    <SearchBox />
                  </Popover>
                </Box>
              )}
              {/* for Iframe cart */}
              <IconButton
                aria-label="cart"
                style={{ outline: 'none' }}
                className="snipcart-checkout"
                sx={{ color: theme.palette.secondary.main }}
              >
                <StyledBadge badgeContent={cartItemsCount} color="primary">
                  <ShoppingCartIcon className="header_text" />
                </StyledBadge>
              </IconButton>
              {/* {!props?.isIframe && (<IconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                sx={{
                  paddingRight: {
                    xs: 0
                  }
                }}
              >
                <MoreIcon />
              </IconButton>)} */}
              {!props?.isIframe && (
                <MUILink href="https://spokbee.com" color="inherit" target="_blank" sx={{ marginTop: '10px' }}>
                  <HomeIcon />
                </MUILink>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {!props?.isIframe && renderMobileMenu}
        {!props?.isIframe && renderMenu}
      </Container>
    </Box>
  );
};

export default HeaderAppBar;
